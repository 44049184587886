<template>
  <v-container v-if="!flights || flights.length === 0">
    <message-card class="pa-12" v-if="hydrated">
      <h2>No Pools Available.</h2>
    </message-card>
    <message-card class="pa-12" v-else>
      <v-progress-circular indeterminate color="color3"></v-progress-circular>
    </message-card>
  </v-container>

  <div v-else>
    <div class="d-flex px-3">
      <v-checkbox :label="`Unlocked Pools Only${unlockedPoolsText}`" color="success" v-model="unlockedOnly" hide-details class="mt-0 mr-3 shrink"></v-checkbox>
      <v-checkbox :label="`Ready Pools Only${readyPoolsText}`" color="success" v-model="readyOnly" hide-details class="mt-0 mr-3 shrink"></v-checkbox>
      <v-checkbox label="Hide Live Scores" color="success" v-model="nolive" hide-details class="mt-0 shrink"></v-checkbox>
      <v-checkbox label="Hide Locked Rounds" color="success" v-model="hideLocked" hide-details class="mt-0 shrink"></v-checkbox>
    </div>
    <div>
      <v-btn
        color="color3"
        text
        x-small
        @click.stop="mini = !mini"
      >{{mini ? 'expand' : 'minfy'}}</v-btn>
    </div>
    <flight-card
      v-for="(flight) in flights"
      :key="`flight_${flight.id}`"
      :flight="flight"
      :noSearch="true"
      :_unlockedOnly="unlockedOnly"
      :_readyOnly="readyOnly"
      :_nolive="nolive"
      :_mini="mini"
      :embeded="true"
    ></flight-card>
  </div>
</template>

<script>
import FlightCard from '@/components/Tournament/Pools/FlightCard.vue'
import { mapGetters } from 'vuex'
import flatten from '../../helpers/ArrayFlatten'

export default {
  data () {
    return {
      unlockedOnly: false,
      readyOnly: false,
      nolive: false,
      mini: false,
      hideLocked: true
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    lockedFlightIds () {
      return this.tournament.publicRounds.filter(f => f.locked).map(m => m.flight ? m.flight.id : -99)
    },
    flights () {
      const base = this.tournament.flights
      return this.hideLocked ? base.filter(f => !this.lockedFlightIds.includes(f.id)) : base
    },
    unlockedPools () {
      return flatten(this.flights.map(f => f.pools.filter(f => !f.locked)))
    },
    unlockedPoolsText () {
      const n = this.unlockedPools.length
      return n > 0 ? ` (${n})` : ''
    },
    readyPools () {
      return this.tournament.pools.filter(f => f.complete && !f.locked)
    },
    readyPoolsText () {
      const n = this.readyPools.length
      return n > 0 ? ` (${n})` : ''
    },
    hydrated () {
      return !this.tournament.publicDivisions.find(f => !f.hydrated)
    }
  },
  methods: {
  },
  components: {
    'flight-card': FlightCard
  }
}
</script>

<style scoped>

</style>
